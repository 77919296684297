<template>
  <v-container class="registration-container pt-10 px-3">
    <h1 class="mb-5">{{ $t("register.card.title") }}</h1>
    <p class="description" v-html="$t('register.card.description')" />
    {{ this.isRegisterWithCard }}
    <v-row class="justify-md-space-between">
      <v-col cols="12" md="8">
        <ResponseMessage :response="response.response || {}"
      /></v-col>
      <v-col cols="12" md="3" v-if="hasErrorMessageWithCode('1')">
        <v-btn
          class="elevation-0"
          color="primary"
          block
          :to="{
            name: 'ResetPasswordRequest',
            path: '/reset-password-request'
          }"
          >{{ $t("profile.edit.password") }}</v-btn
        >
      </v-col>
      <v-col v-if="hasErrorMessageWithCode('2')">
        <v-btn
          class="elevation-0"
          color="primary"
          block
          :to="{
            name: 'RegistrationNoCard',
            path: '/registration-nocard'
          }"
          >{{ $t("login.buttonRegister") }}</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-card class="w-100 pa-2" elevation="0">
        <!-- REGISTRATION STEP: 1 -->
        <UserData
          v-if="step === 1"
          :userData.sync="userData"
          :supportData.sync="supportData"
          :hasFidelityCard="true"
          :isRegisterWithCard="isRegisterWithCard"
          :isFiscalCode.sync="isFiscalCode"
          :registrationType="registrationType"
          @back="goToPreviousStep(true)"
          @submit="isRegisterWithCard ? findUser('form') : goToNextStep()"
        />
        <!-- STEP 2 && VISIBLE ONLY WITH REGISTRATION WITHOUT CARD-->
        <UserAddress
          :userData.sync="userData"
          :supportData.sync="supportData"
          v-if="step === 2 && !isRegisterWithCard"
        />
        <!-- REGISTRATION STEP: 2 -->
        <UserEmailAddress
          v-if="step === 2"
          :userData.sync="userData"
          :supportData.sync="supportData"
          @back="goToPreviousStep"
          @submit="registerUser"
        />
      </v-card>
    </v-row>
  </v-container>
</template>
<style lang="scss">
.registration-container {
  color: var(--v-default-base);
  h1 {
    font-size: 50px !important;
  }
  .description {
    font-size: 20px;
  }
  .v-card {
    border: 1px solid var(--v-grey-lighten3);
    color: var(--v-primary-base);
    .v-card__title {
      font-size: 30px;
      word-break: break-word;
      line-height: 42px;
    }
    p {
      font-weight: 400 !important;
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    h1 {
      font-size: 25px !important;
      line-height: 3rem;
    }
    .description {
      font-size: 16px !important;
    }
    .v-card {
      height: auto;
    }
  }
}
</style>

<script>
import UserData from "@/components/profile/form/UserData";
import UserEmailAddress from "@/components/profile/form/UserEmailAddress";
import UserAddress from "@/components/profile/form/UserAddress";
import AliRegistrationService from "@/service/abbondanzaRegistrationService";
import ResponseMessage from "@/components/ResponseMessage";
import AnalyticsService from "~/service/analyticsService";
import SecurityService from "~/service/securityService";
import { mapGetters } from "vuex";

export default {
  name: "RegistrationForms",
  props: {
    isProfileForm: { type: Boolean, default: false },
    showPrivacyFields: { type: Boolean, default: true }
  },
  components: {
    UserData,
    UserAddress,
    UserEmailAddress,
    ResponseMessage
  },
  data() {
    return {
      step: 1,
      isRegisterWithCard: null,
      activePicker: null,
      supportData: {
        privacy1: true,
        privacy2: true,
        bornCountry: {
          name: null
        }
      },
      userData: {
        fidelityCard: null,
        phone: null,
        email: null,
        person: {
          firstName: null,
          lastName: null,
          birthDate: null
        }
      },
      address: null,
      askFirstName: true,
      askLastName: true,
      fidelityFound: false,
      formStepId: null,
      response: {},
      trackId: new Date().getTime(),
      logTrackStr: this.trackId + " ",
      registrationType: "OldUser",
      askFiscalCode: true,
      showFiscalCode: true,
      isFiscalCode: true
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    })
  },
  methods: {
    back() {
      this.$router.go(-1);
    },

    findUser() {
      this.userData.fidelityCard = this.userData.fidelityCard.replace(
        /[^0-9]/g,
        ""
      );
      let _this = this;
      console.log(
        "USER INSERTED: ",
        this.userData,
        this.userData.person.firstName,
        this.userData.person.lastName,
        this.userData.person.birthDate
      );
      AliRegistrationService.findUser(
        this.userData.person.firstName,
        this.userData.person.lastName,
        this.$dayjs(this.userData.person.birthDate).format("DD/MM/YYYY"),
        this.userData.fidelityCard
      )
        .then(function(response) {
          if (response.response.status == 0) {
            _this.fidelityFound = true;
            _this.userData = response.data.user;
            //  _this.askFiscalCode = response.data.askFiscalCode;
            _this.askFirstName = response.data.askFirstName;
            _this.askLastName = response.data.askLastName;
            //   if (!_this.askFiscalCode) {
            //    _this.userData.person.gender = "F";
            //   }
            _this.goToNextStep();
          }
          _this.response = response;
        })
        .catch(error => {
          console.error("error", error);
        });
    },
    hasErrorMessageWithCode(code) {
      const { errorsMessage } = this.response.response || {};
      return (
        errorsMessage &&
        errorsMessage.length > 0 &&
        errorsMessage[0].code === code
      );
    },
    registerUser(oldCustomer, validBornDate) {
      if (validBornDate === false) {
        this.response = {
          errors: [{ error: "Formato della data di nascita non corretto" }]
        };
        return;
      }
      if (this.isRegisterWithCard) {
        this.userData.registrationModule = this.response.data.registrationModule;
      }

      this.userData.force = this.response.status === 2;
      let force = this.response.status === 2;

      // fix for missing city
      if (!this.userData.billingAddress.city) {
        this.userData.billingAddress.city = "";
      }
      // fix province
      if (typeof this.userData.billingAddress.province === "object") {
        this.userData.billingAddress.province = this.userData.billingAddress.province.name;
      }
      let _this = this;
      //TODO: verificare chiamata ad AnalyticsService
      AliRegistrationService.registerUser(this.userData).then(function(data) {
        if (data.response.status == 0) {
          SecurityService.refreshLogin(true).then(function() {
            console.log(data);
            AnalyticsService.signUp("Registration", data.user.userId);

            if (force || _this.disclaimerList) {
              _this.$router.push({
                name: "RegistrationCompletedAgreement",
                params: { mail: _this.userData.email }
              });
            } else {
              _this.$router.push({
                name: "RegistrationCompleted",
                params: { mail: _this.userData.email }
              });
            }
          });
        } else {
          let error = data.response.data.response.errors[0].error;
          global.EventBus.$emit("error", {
            message: error
          });
        }
      });
    },
    validate() {
      let _this = this;
      setTimeout(function() {
        if (_this.$refs.form) {
          _this.valid = _this.$refs.form.validate();
          if (_this.isProfileForm) {
            _this.$emit("validate");
          }
        }
      }, 200);
    },
    onUserDataSubmit() {
      this.goToNextStep();
    },
    goToNextStep() {
      if (!this.userData.fidelityCard) {
        this.resetFormFields(this.isFiscalCode);
      }
      this.step = this.step + 1;
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      });
    },
    goToPreviousStep(skipStepZero) {
      if (skipStepZero) {
        this.$router.push({ path: "/registration-user" });
      } else {
        this.step = this.step - 1;
        this.$nextTick(() => {
          window.scrollTo(0, 0);
        });
      }
    },
    resetFormFields(isFiscalCode) {
      if (this.formStepId == 1) {
        if (isFiscalCode) {
          this.birthDate = null;
          this.userData.person.birthPlace = null;
        } else {
          this.userData.person.fiscalCode = null;
        }
      }
    },
    detectUserDetails() {
      var customerDetails = "";
      if (this.userData) {
        if (this.userData.email) {
          customerDetails = customerDetails + this.userData.email;
        }
        if (this.userData.fidelityCard) {
          if (customerDetails != "") {
            customerDetails = customerDetails + " ";
          }
          customerDetails = customerDetails + this.userData.fidelityCard;
        }
        if (customerDetails != "") {
          customerDetails = ": " + customerDetails;
        }
      }
      return customerDetails;
    },
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    }
  },
  created() {
    this.registrationType = this.$route.params.type || "OldUser";
    this.isRegisterWithCard = this.$route.params.isRegisterWithCard;
    if (!this.isRegisterWithCard) this.step = 1;

    /*if (
      this.$route.path === "/registration-card" &&
      !this.userData.fidelityCard
    ) {
        this.back();
    } else {
      this.loadCountriesList();
      this.showFiscalCode = this.askFiscalCode;
    }*/
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    }
  }
  /* mounted() {
    this.loadDisclaimer();
  }*/
  /*async mounted() {
    await this.getProvinces(this.customCountries[0], false);
    if (!this.isProfileForm) {
      this.changeCountry(this.customCountries[0]);
    }
  }*/
};
</script>
